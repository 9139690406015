$(document).ready(function(){

	$('.file-upload input[type="file"]').change(function(){
		var list_class = 'files-' + $(this).attr('id');
		var input = this;

		if (input.files) {
	        for (var i = input.files.length - 1; i >= 0; i--) {
	        	$('.'+list_class).append('<li>'+input.files[i].name+'</li>');
	        }
	    }
	});

});